import React, { useEffect, useRef } from "react"
import BlockWrapper from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BlockWrapper"
import Image from "@yumgmbh/gatsby-theme-yum-components/src/components/atoms/Image"

import Background from "../../images/start-stars-bg.jpg"
import Foreground from "../../images/start-foreground.png"
import TitleStencil from "../../images/start-stencil.svg"

import { gsap } from "gsap"
import { ScrollTrigger } from "gsap/ScrollTrigger"

const StartHeroBlock = ({ block }) => {
  useEffect(() => {
    if (window && document && navigator) {
      window.setTimeout(function () {
        gsap.registerPlugin(ScrollTrigger)
        let tl = gsap.timeline()
        gsap.utils.toArray(".mm-hero-parallax").forEach((layer) => {
          const depth = layer.dataset.depth
          const movement = -(layer.offsetHeight * depth)
          tl.to(layer, {
            duration: 1,
            y: movement,
            ease: "none",
            scrollTrigger: {
              trigger: ".mm-hero",
              start: "top top", // when the top of the trigger hits the top of the viewport
              end: "bottom top", // end after scrolling 500px beyond the start
              scrub: true,
            },
          })
        })
        ScrollTrigger.refresh(true)
      }, 1000)
    }
  }, [])

  return (
    <BlockWrapper
      block={block}
      blockPadding="pt-0 pb-0"
      blockWidth="screen"
      className="mm-hero"
    >
      <div
        className="relative block w-full overflow-hidden"
        style={{ height: "calc(100vh - 6rem)" }}
      >
        <div
          className="fixed inset-0 overflow-hidden transition translate-x-0"
          style={{ zIndex: "-1" }}
        >
          <Image
            className="object-cover object-left-bottom w-full h-full"
            image={Background}
          />
        </div>

        <div className="absolute left-0 right-0 transform -translate-y-1/2 top-1/3">
          <div
            className="max-w-screen-sm px-8 mx-auto mm-hero-parallax"
            data-depth="-0.80"
          >
            <Image className="block w-full h-auto" image={TitleStencil} />
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 h-full overflow-hidden lg:h-auto">
          <Image
            className="object-cover object-center w-full h-full lg:w-full lg:h-auto"
            image={Foreground}
          />
        </div>
      </div>
    </BlockWrapper>
  )
}

export default StartHeroBlock
