import React from "react"
import { graphql } from "gatsby"
import Layout from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Layout"
import BodyBlocks from "@yumgmbh/gatsby-theme-yum-components/src/components/technical/BodyBlocks"
import StartHeroBlock from "../components/blocks/StartHeroBlock"
import SEO from "@yumgmbh/gatsby-theme-yum-components/src/components/modules/Seo"

const IndexPage = ({ data }) => {
  const { story } = data

  const content = JSON.parse(story.content)

  return (
    <Layout banner={{ title: content.banner_text, titleshort: content.banner_text_short, link: content.banner_link }}>
      <SEO content={content} />
      <StartHeroBlock block={{}} />
      <div className="bg-white">
        <BodyBlocks body={content.body} />
      </div>
    </Layout>
  )
}

export const data = graphql`
  {
    story: storyblokEntry(
      field_component: { eq: "start_page" }
      full_slug: { eq: "home" }
    ) {
      name
      content
      full_slug
    }
  }
`

export default IndexPage
